import { ChainId } from '@udotcash/u-exchange-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.RSK]: '0x10AF1B3BBA6cDf672096eD57Fa16dD06C82ba7B0',
  [ChainId.RSKTEST]: '0x4Eeebb5580769Ba6d26bFD07bE636300076d1831',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BINANCE]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.BINANCETEST]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.XDAI]: '0x0df07C4557311F779AD668aEaCdB61c9eB10dFf7',
  [ChainId.POLYGON]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d'

}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
